$(function () {
    // re-render if is mobile
    let browserWidth = window.innerWidth;
    let browserHeigh = window.innerHeight;
    if(browserWidth < 480 || browserHeigh < 480){
        let freeFoodEarned = $('#total-free-food-earned').text();
        let freeFoodAvailable = $('#total-referral-free-now').text();
        let PlaterateFreeFood = $('#total-platerate-free-food').text();
        let RestaurantFreeFood = $('#total-restaurant-free-food').text();
        let html=`
            <div class="form-group text-center">
                <div class="h4" style="color: grey">Free food earned to date</div>
                <div id="total-free-food-earned" class="h4" style="font-weight: bold; color: black;">${freeFoodEarned}</div>
            </div>
            <div class="form-group ml-5 mr-5" style="border-top: 2px solid grey;"></div>
            <div class="text-center">
                <div class="h5" style="color: grey;">Free food available now</div>
                <div id="total-referral-free-now" class="h4" style="font-weight: bold; color: black;">${freeFoodAvailable}</div>
            </div>
            <div class="text-center" style="font-size: 25px;">=</div>
            <div class="text-center">
                <div class="h5" style="color: grey;">At ANY restaurant</div>
                <div id="total-platerate-free-food" class="h4" style="font-weight: bold; color: black;">${PlaterateFreeFood}</div>
            </div>
            <div class="text-center" style="font-size: 25px;">+</div>
            <div class="form-group text-center">
                <div class="h5" style="color: grey;">At INDIVIDUAL restaurants</div>
                <div id="total-restaurant-free-food" class="h4" style="font-weight: bold; color: black;">${RestaurantFreeFood}</div>
            </div>
        `;
        $('.mobile-desktop-free-food').html(html)
    }

    $(document).ready(function(){
        if($('#restaurant-free-food-details').length) $('#restaurant-free-food-details').iziModal({
            width: '500px',
            zindex:9999,
            onClosed: function(){
                let modal = $('#restaurant-free-food-details').data('open-modal')
                if(modal) $(modal).iziModal('open')
            }
        })
        if($('#restaurant-specific-free-food-details').length) $('#restaurant-specific-free-food-details').iziModal({
            width: '500px',
            onClosed: function(){
                let modal = $('#restaurant-specific-free-food-details').data('open-modal')
                if(modal) $(modal).iziModal('open')
            }
        })
        if($('#userId').val()){
            total_my_free_food().then(res =>{
              if(window.location.href.includes('free-food-at-restaurant')) getfreeFoodFromReferrals();
              if(window.location.href.includes('free-food-at-restaurant')) get_platerate_gift_history();
            })
        }
        $("#referral-accordion-area").on("hide.bs.collapse", function(){
            $('.referral-accordion').find('.icon-collapse').removeClass('fa-minus').addClass('fa-plus')
        });
        $("#referral-accordion-area").on("show.bs.collapse", function(){
            $('.referral-accordion').find('.icon-collapse').removeClass('fa-plus').addClass('fa-minus')
        });

        filterRestaurantListOfIncentive('#free-food-section-profile', false, true);
    })
    // Get User Location
    // function get_user_location() {
    //     return $.ajax({
    //         url: '/user/get-location',
    //         type: "GET"
    //     })
    //         .then((res) => {
    //             __user_location = res
    //             if (_.size(__user_location)) {
    //                 return filterRestaurantListOfIncentive('#free-food-section-profile', false, true);
    //             } else {
    //                 return IP_location().then(()=>{
    //                     return filterRestaurantListOfIncentive('#free-food-section-profile', false, true);
    //                 })
    //             }
    //         })
    // }

    function IP_location() {
        let ipapi_key = $("#ipapiSecret").val().toString()
        return $.ajax('https://ipapi.co/json/?key=' + ipapi_key)
            .then(res => {
                __user_location = res
            })
    }

    var userDidSave = true;
    $('#free-food-restaurant-search').on('keyup', function(e) {
        filterRestaurantListOfIncentive();
    });

    $('.reset-btn').click(function(){
        $('.freefoodsearch').val('');
    });

/* why do we have this
    $(document).ready(function(){
        $('.free-food-search-radius').trigger('input')
    })
*/

    $('#freefoodlat').on('change', function(e){
        e.stopPropagation();
        filterRestaurantListOfIncentive();
    });

    // $('#free-food-show-all-restaurants').prop('checked', true);
    $('.ff-search-restaurants').find('input').prop('disabled', true);
    // $('#free-food-show-all-restaurants').on('click',function(){
    //     $('.ff-search-restaurants').find('input').prop('disabled', $(this).prop('checked'));
    // })

    var RESTAURANT_CREDITS = []
    const filterRestaurantListOfIncentive = function(section = '#free-food-section-profile', modal = false, all = false) {
        userDidSave = true;
        var lat = __user_location.latitude
        var long = __user_location.longitude
        var reqData = {
            show_all: all,
            lat: lat,
            long: long
        };
        if (_.trim($(`${section} #freefoodlocationinput`).val())) {
            delete reqData.lat;
            delete reqData.long;
            reqData.address = _.trim($(`${section} #freefoodlocationinput`).val())
        }
        reqData.radius = _.trim($(`${section} #search-radius`).val())
        if( _.trim($(`${section} #freefoodsearch`).val())) {
            reqData.searchinput = _.trim($(`${section} #freefoodsearch`).val())
        }
        // reqData.show_all = $(`${section} #free-food-show-all-restaurants`).prop('checked');
        
        // $(`${section}`).find('.list-restaurants').html(`<div class="col-12 text-center">Loading Restaurants...</div>`);
        $.ajax({
            url: '/users/restaurantIncentive',
            type: "POST",
            data: reqData,
            dataType: 'json',
            success: function(creditDetailsOfRestaurant) {
                _.forEach(creditDetailsOfRestaurant, item => {
                    _.remove(RESTAURANT_CREDITS, el => el.id == item.id)
                    RESTAURANT_CREDITS.push(item)
                })
                if (modal) {
                    let callback = function (data) {
                        return render_modal_restaurant_credits(data)
                    }
                    return __paginate("#free-food-pagination", creditDetailsOfRestaurant, 12, callback)
                } else {
                    return renderRestaurants(creditDetailsOfRestaurant, '#restaurant-incentive-accordion', section);
                }
            },
            statusCode: {
                '404': function() {
                    renderRestaurants([], '#restaurant-incentive-accordion', section);
                }
            }
        });
    };

    window.__refresh_free_food = ()=>{
        filterRestaurantListOfIncentive()
        // GetCurrentRestaurantIncentive()
        get_platerate_gift_history()
    }
    if(window.location.href.includes('myfreefood')){
        filterRestaurantListOfIncentive()
        // GetCurrentRestaurantIncentive()
        get_platerate_gift_history()
    }
    $("#tabrestaurantcredit").on('click',function(){
        filterRestaurantListOfIncentive('#free-food-section-profile', false, true)
        // GetCurrentRestaurantIncentive()
        get_platerate_gift_history()
        $(this).unbind("click")
    });
    function GetCurrentRestaurantIncentive(creditDetailsOfRestaurant, section="") {
        if(!creditDetailsOfRestaurant) return filterRestaurantListOfIncentive('#free-food-section-restaurant')
        let venueId = $('#venueId').val();
        // $.promisedAjax({
        //     url: '/users/restaurantIncentiveSpecified',
        //     type: "POST",
        //     dataType:'json',
        //     data: { venueId: venueId },
        // }).then(creditDetailsOfRestaurant => {
        //   renderRestaurants(creditDetailsOfRestaurant, '#current-restaurant-incentive', '#free-food-section-restaurant');
        //   if(refresh_elem == 'restaurant-current') $('#restaurant-current').collapse('show')

        if(section=="#modal-restaurant-specific-credits-details") {
            renderRestaurantSpecified(creditDetailsOfRestaurant, '#current-restaurant-incentive', '#free-food-section-restaurant')
        }
        else {
            var venueSpecified = [];
            var venueSpecifiedObj = creditDetailsOfRestaurant.find(items => items.restaurantId == venueId)
            venueSpecified.push(venueSpecifiedObj);
            if(venueSpecified) {
                renderRestaurantSpecified(venueSpecified, '#current-restaurant-incentive', '#free-food-section-restaurant');
                if(refresh_elem == 'restaurant-current') $('#restaurant-current').collapse('show')
            }
          else {
          $('#current-restaurant-incentive').html(`
              <div class="form-row col-xs-12 col-sm-12 col-md-12 text-center">
                  <p style="font-weight: bold; color: #DD0000">Can't get the current restaurant incentives information!</p>
                  <button type="button" class="btn btn-success try-get-restaurant-incentives">Try again</button>
              </div>
          `)
          $('.try-get-restaurant-incentives').on('click',function(){
              get_platerate_gift_history();
              GetCurrentRestaurantIncentive(creditDetailsOfRestaurant);
              $(this).unbind("click");
          })
        }
      }
    }
    var my_credits_infor = {}
//201029 i dont think this is there in redesign    if($('#venueId').val()) getfreeFoodTotalInfo(); //calling it on load in order to display totoal free food in the accordion heading also
    function getfreeFoodTotalInfo(){
        let venueId = $('#venueId').val();
        let url = `/users/freeFoodTotal?venueId=${venueId}`;
        return $.ajax({
            url: url,
            type: "GET"
        }).then((result)=>{
        if (result && result.data){
            my_credits_infor = result.data;
            $('#total-free-food-earned').text(`$${(result.data.totalFreeFoodEarned) ? parseFloat(result.data.totalFreeFoodEarned).toFixed(2) : '0' }`);
            $('#total-referral-free-now').text(`$${(result.data.totalFreeFoodTotal) ?  parseFloat(result.data.totalFreeFoodTotal).toFixed(2) : '0' }`);
            $('#total-platerate-free-food').text(`$${(result.data.totalPlaterateFreeFood) ?  parseFloat(result.data.totalPlaterateFreeFood).toFixed(2) : '0' }`);
            $('#total-restaurant-free-food').text(`$${(result.data.totalRestaurantFreeFood) ?  parseFloat(result.data.totalRestaurantFreeFood).toFixed(2) : '0' }`);
            $('#your-revenue-share').text(`${(result.data.referral) ?  parseFloat(result.data.referral).toFixed(2) : '0' }%`);

            $('#total-free-food-earned-profile').text(`$${(result.data.totalFreeFoodEarned) ? parseFloat(result.data.totalFreeFoodEarned).toFixed(2) : '0' }`);
            $('#total-referral-free-now-profile').text(`$${(result.data.totalRestaurantFreeFood) ? parseFloat(result.data.totalRestaurantFreeFood).toFixed(2) : '0' }`);
            $('#total-platerate-free-food-profile').text(`$${(result.data.totalPlaterateFreeFood && result.data.totalPlaterateFreeFood >0) ? parseFloat(result.data.totalPlaterateFreeFood).toFixed(2) : '0' }`);
            $('#total-restaurant-free-food-profile').text(`$${(result.data.totalRestaurantFreeFood) ? parseFloat(result.data.totalRestaurantFreeFood).toFixed(2) : '0' }`);
            $('#your-revenue-share-profile').text(`${(result.data.referral) ? parseFloat(result.data.referral).toFixed(2) : '0' }%`);

            $('.platerate-credits-balance').text(`$${parseFloat(result.data.totalPlaterateFreeFood).toFixed(2)}`)
        }
        });
    }

    function total_my_free_food() {
        return $.ajax({
            url: '/users/freeFoodTotal',
            type: "GET"
        }).then((result)=>{
            if (!result.data) return true
            my_credits_infor = result.data
            //changes for wrong free food issue
            //let freefood = Number(result.data.totalFreeFoodTotal)
            let freefood = Number(result.data.totalAvailRestaurant) + Number(result.data.totalPlaterateFreeFood);
            console.log("result.data", result.data)
            let totalAvailRestaurant= Number(result.data.totalPlaterateFreeFood);
            let NumberFreefood = parseFloat(freefood).toFixed(2);
            let  total_referral_free= Number(result.data.totalRestaurantFreeFood)   + Number(result.data.referral);
            console.log("result.data.totalRestaurantFreeFood====",result.data.totalRestaurantFreeFood);
            console.log("result.data.referral====",result.data.referral);

            if(NumberFreefood>0) $('.headingfreefoodLabel').text(`$${NumberFreefood}`);
            $('#total-referral-free-now').text(`$${(NumberFreefood) ? parseFloat(NumberFreefood).toFixed(2) : '0' }`);
            $('#total-free-food-earned').text(`$${(result.data.totalFreeFoodEarned) ? parseFloat(result.data.totalFreeFoodEarned).toFixed(2) : '0' }`);
            $('#total-platerate-free-food').text(`$${(result.data.totalPlaterateFreeFood) ?  parseFloat(result.data.totalPlaterateFreeFood).toFixed(2) : '0' }`);
            $('#total-restaurant-free-food').text(`$${(result.data.totalRestaurantFreeFood) ?  parseFloat(result.data.totalRestaurantFreeFood).toFixed(2) : '0' }`);
            
            $('#total-free-food-earned-profile').text(`$${(result.data.totalFreeFoodEarned && result.data.totalFreeFoodEarned >0) ? parseFloat(result.data.totalFreeFoodEarned).toFixed(2) : '0' }`);
            $('#total-referral-free-now-profile').text(`$${parseFloat(total_referral_free).toFixed(2)}`);
            $('#total-platerate-free-food-profile').text(`$${totalAvailRestaurant  ? parseFloat(totalAvailRestaurant).toFixed(2) : '0' }`);
            $('#total-restaurant-free-food-profile').text(`$${(result.data.totalAvailRestaurant && result.data.totalAvailRestaurant >0) ? parseFloat(result.data.totalAvailRestaurant).toFixed(2) : '0' }`);
            $('#your-revenue-share-profile').text(`${(result.data.referral) ? parseFloat(result.data.referral).toFixed(2) : '0' }%`);
            $('.platerate-credits-balance').text(`$${parseFloat(result.data.totalPlaterateFreeFood).toFixed(2)}`)
        })
    }

    $("#restaurantCreditProfileBtn").on("click", function(e) {
        e.preventDefault()
            let venueId =  '';
            let venueName = $("#credit_venueName").val();
            let venueAddress = $("#credit_venueAddress").val();
            let toUserEmail = $('#credit_to_profile').val();
            let credit_amnt = $('#credit_amnt_profile').val();
            let credit_reason = $('#credit_reason_profile').val();
            let credit_expiry = $('#credit_expiry_profile').val();
            let credit_type = $('#restcredit_type').val();
            //check mandatory entries
        if(Number(credit_amnt)<=0){
            toastr.error('Amount should be > 0');
            return false;
        }
        if(credit_reason==""){
            toastr.error('Please provide credit reason');
            return false;
        }

            //if toUserData exist
        const toUserData = validateUserEmail(toUserEmail);
        if(toUserData){
            var userName = toUserData.profile.contactInfo.firstName + toUserData.profile.contactInfo.lastName;
            var toUserId = toUserData._id;
            if(toUserData.profile && toUserData.profile.permissionGroups){
            var userType = toUserData.profile.permissionGroups[0];
            } else {
                toastr.error('User Type is not defined.');
                return false;
            }
            if(Number(credit_amnt)<=0){
                toastr.error('Amount should be > 0');
                return false;
            }
            if(credit_reason==""){
                toastr.error('Please provide credit reason');
                return false;
            }
            let data = {
                    "txnMadeBy":toUserId, 				//who receives credit
                    "venueId": venueId,
                    "venueName": venueName,
                    "venueAddress":venueAddress,
                    "toUserEmail":toUserData.local.email,
                    "creditAmount":credit_amnt,
                    "creditReason":credit_reason,
                    "creditExpiryDate":credit_expiry,
                    "creditType": credit_type           //Restaurant Admin,Platerate Admin
            };
            $("#restaurantCreditProfileBtn").prop( "disabled", true );
            $.ajax({
                url: '/credit/saveRestaurantCredit',
                method: 'POST',
                contentType: 'application/json; charset=utf-8',
                data : JSON.stringify({ data }) ,
                dataType: 'json'
                })
                .then(function (res) {
                    $("#restaurantCreditProfileBtn").prop( "disabled", false );
                    if (res && res.ok) {
                    toastr.success(res.ok);
                    // $('#creditProfile').load(location.href + ' #customerCreditProfile');
                    //clear out input fields
                        $('#credit_to_profile').val("");
                        $('#credit_amnt_profile').val("");
                        $('#credit_reason_profile').val("");
                        $('#credit_expiry_profile').val("Forever");
                    } else {
                    toastr.error(res.error);
                        return false;
                    }
                })
                .catch(()=>{
                    $("#restaurantCreditProfileBtn").prop( "disabled", false );
                })
        }else{
            toastr.error("Entered email is not a valid Platerate User");
        }

    });
    function validateUserEmail(email) {
        var result = '';
        $.ajax({
            url: '/user/validateEmail',
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
            data : JSON.stringify({
                email: email
            }),
            async : false,
            dataType: 'json',
            success: function(data) {
                if(data.code === 0)
                {
                    result = data.data;
                }else{
                    Swal.fire("Oops ", res.error, "error");
                }
        }
        });
        return result;
    }

    window.refreshFreeFoodScreen = () => {
      getfreeFoodTotalInfo().then(res => {
        // GetCurrentRestaurantIncentive();
        get_platerate_gift_history();
        filterRestaurantListOfIncentive();
        filterRestaurantListOfIncentive('#free-food-section-restaurant');
      });
    }

     //error for menu expand in safari, so below was commented, but uncommenting in order to show referrals
     function render_referral_credits(data) {
        let html = ``;
        _.forEach(data, item => {
            // Check if item.earned is not equal to 0
            if (parseFloat(item.earned || 0) !== 0) {
                html += `
                    <div class="col-12 col-md-6 mb-3">
                        <div class="d-flex justify-content-between align-items-center elevation-1 p-1" style="border-radius: 20px">
                            <div class="d-flex col-9 padding-0 align-items-center">
                                <img class="avatar" src="${item.profile.avatar || '/images/avatar.png'}">
                                <div  title="${item && item.name? item.name: ''}" class="text-referal-credit">${item && item.name? item.name: ''}</div>
                            </div>
                            <div class="col-3">$${parseFloat(item.earned || 0).toFixed(2)}</div>
                        </div>
                    </div>
                `;
            }
        });
        return `<div class="row mt-3 mx-md-3">${html}</div>`;
    }
    function getfreeFoodFromReferrals(){
        let userRegDate = $("#user-registrationData").val();
        let currentYear = moment().year();
        let regtrYear = '';
        let regtrMonth = '';
        if(userRegDate!= null && userRegDate!= undefined ){
            regtrYear = moment(userRegDate).year();
            regtrMonth = moment(userRegDate).month();
        }
        let currMonth = moment().month();
        return $.ajax({
            url: '/users/freeFoodFromReferrals',
            type: "GET"
        }).then((result)=>{
            let data = result.data
            if(!_.size(result.data)) {
                $('#month-referral-accordion').html('<div>No data available.</div>');
            }
            let months = ["Dec","Nov","Oct","Sep","Aug","Jul","Jun","May","Apr","Mar","Feb","Jan"];
            let html = '' 
            let c_mon = 11 - moment().month();
            let monthTo = 12;
            if(currentYear == regtrYear){
              monthTo = 12 - regtrMonth;
            }
            for (let i = c_mon; i < monthTo; i++) {
                html += `
                    <li class="list-group-item">
                        <div class="form-inline justify-content-between p-2" data-toggle="collapse" data-target="#collapse-referral-${months[i]}" aria-expanded="false" aria-controls="collapse-referral-${months[i]}" style="cursor: pointer">
                            <div class="" style="font-size: 20px; color: green; font-weight: bold">
                                <i class="icon-collapse-${months[i]} fa fa-plus" aria-hidden="true"></i> ${months[i]}
                            </div>
                            <div class="" style="font-size: 20px; color: black; font-weight: bold">
                                $${data && data[i] && data[i].freeFoodEarned? parseFloat(data[i].freeFoodEarned).toFixed(2): 0.00}
                            </div>
                        </div>
                        <div class="collapse collapse-month-referral" id="collapse-referral-${months[i]}" data-month="${months[i]}">
                            ${render_referral_credits(data[i].friends)}
                        </div>
                    </li>
                `;
            }

            html = `
                <ul class="list-group list-group-flush" style="color: black">
                    ${html}
                </ul>
            `;
            $('#month-referral-accordion').html(html);
            $('#month-referral-accordion').find('.collapse-month-referral').on('hidden.bs.collapse', function () {
                let elem = $(this).data('month')
                $(`.icon-collapse-${elem}`).removeClass('fa-minus').addClass('fa-plus')
            })
            $('#month-referral-accordion').find('.collapse-month-referral').on('shown.bs.collapse', function () {
                let elem = $(this).data('month')
                $(`.icon-collapse-${elem}`).removeClass('fa-plus').addClass('fa-minus')
            })
        });
    }


    if($('#modal-restaurant-credits-details').length) $('#modal-restaurant-credits-details').iziModal({
        width: '1000px',
        onOpening: function(){
            return filterRestaurantListOfIncentive('#modal-restaurant-credits-details', true, true);
        }
    })

    $('body').on('click', '#restaurantFilter', function(e) {
        $(`#modal-restaurant-credits-details`).find('.list-restaurants').html(`<div class="col-12 text-center">Loading Restaurants...</div>`);
        filterRestaurantListOfIncentive('#modal-restaurant-credits-details', true);
    });

    $('body').on('click', '#restaurantFilterInAbout', function(e) {
        $(`#restaurant-specific-incentives`).html(`<div class="col-12 text-center">Loading Restaurants...</div>`);
         filterRestaurantListOfIncentive('#modal-restaurant-specific-credits-details');
    });

    $('#see-all-restaurants-credits').on('click', function(){
        $('#modal-restaurant-credits-details').iziModal('open')
    })

    const setRadius = function (prosses, prossesMax, min, max) {
        let length = max - min;
        let rate = prosses / prossesMax
        let radius = min + (rate * length);
        return radius.toFixed(1);
    }
    $('#modal-restaurant-credits-details').find('.free-food-search-radius').on('input', function(e){
        //set the radius values as max 50
        let radius = Number($(this).val()||0);
        if (Number(radius) < 25) {
            radius = setRadius(radius, 25, 0.1, 0.5)
        } else if (Number(radius) < 50) {
            radius = setRadius(radius - 25, 25, 0.5, 5)
        } else {
            radius = setRadius(radius - 50, 50, 5, 100)
        }
        $('#modal-restaurant-credits-details').find('#search-radius').val(radius)
        $('#modal-restaurant-credits-details').find(`.free-food-search-radius-text`).text(radius);
    });

    function render_modal_restaurant_credits(data){
        let $list_restaurants = $('#modal-restaurant-credits-details').find('.list-restaurants')
        if(!_.size(data)) {
            return $list_restaurants.html(`<div class="text-center w-100 mt-3">No restaurant found for current filter options</div>`);
        }
        $list_restaurants.html('')
        _.forEach(data,(restaurant, index)=> {
            restaurant.totalCredit = restaurant.totalCredit || 0;
            restaurant.currentCredits = restaurant.currentCredits ||0;
            let expires_credits = get_expired_credit(restaurant)
            let html = `
                <div class="card card-body col-12 col-md-4 p-1" style="border: none">
                    <div class="w-100 d-flex align-content-between flex-wrap p-2" style="min-height: 150px; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); transition: all 0.3s cubic-bezier(.25,.8,.25,1); border: none">
                        <div class="row">
                            <div class="col-8 text-left">
                                <div class="font-weight-bold" style="font-size: 19px; color: #02843d;">
                                    <a href="/restaurant/${restaurant.slug || restaurant.id}">${restaurant.name}</a>
                                </div>
                                <div class="font-italic" style="font-size: 14px">${restaurant.address}</div>
                            </div>
                            <div class="col-4 text-right">
                                <div class="font-weight-bold" style="color: #02843d;">
                                    <em>${ restaurant && restaurant.currentCredits && restaurant.currentCredits >0 ? '$'+ parseFloat(restaurant.currentCredits).toFixed(2):0}</em>
                                </div>
                            </div>
                            <div class="col-12 text-left mt-2">
                                ${ expires_credits && expires_credits.credits ?
                                    `<div class="font-italic" style="color: #BF2519">
                                        $${parseFloat(expires_credits.credits).toFixed(2)} expires in ${expires_credits.days +1}  days
                                    </div>`
                                    : ''
                                }
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-between align-items-end">
                            <a class="ff-details-for-${restaurant.id}" href="javascript:void(0)" style="color: #02843d; font-size: 0.75rem;">
                                <i class="fa fa-info-circle mr-1" aria-hidden="true" style="font-size: 18px; vertical-align: bottom;"></i><em>See Details</em>
                            </a>
                            ${ parseFloat(restaurant.currentCredits) > 0 ? `
                            <a class="btn btn-success" href="/restaurant/${restaurant.slug || restaurant.id}" style="padding: 4px 28px; font-size: 14px;">
                                Use
                            </a> `:``}
                        </div>
                    </div>
                </div>
            `;
            $list_restaurants.append(html)
            $list_restaurants.find(`.ff-details-for-${restaurant.id}`).on('click', function(){
                return list_free_food_details(restaurant, '#modal-restaurant-credits-details')
            })
        })
    }

    const renderRestaurants = function(restaurantIncentives, elementToRender = '#restaurant-incentive-accordion', section = '#free-food-section-profile') {
        if(elementToRender.length <=0) return;
        let $list_restaurants = $(`${section} ${elementToRender}`).find('.list-restaurants')
        if(!_.size(restaurantIncentives)) {
            return $list_restaurants.html(`<div class="text-center w-100 mt-3">No restaurant found for current filter options</div>`);
        }
        $list_restaurants.html('')

        let sliders_html = ''
        _.forEach(restaurantIncentives,(restaurant, index)=> {
            restaurant.totalCredit = restaurant.totalCredit || 0;
            restaurant.currentCredits = restaurant.currentCredits ||0;
            let expires_credits = get_expired_credit(restaurant)
            sliders_html += `
                <div class="swiper-slide p-3" style="box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); transition: all 0.3s cubic-bezier(.25,.8,.25,1); border: none; border-radius: 10px;">
                    <div class="w-100 d-flex align-content-between flex-wrap" style="min-height: 150px">
                        <div class="row">
                            <div class="col-8 text-left">
                                <div class="font-weight-bold" style="font-size: 19px; color: #02843d;">
                                    <a href="/restaurant/${restaurant.slug || restaurant.id}">${restaurant.name}</a>
                                </div>
                                <div class="font-italic" style="font-size: 14px">${restaurant.address}</div>
                            </div>
                            <div class="col-4 text-right">
                                <div class="font-weight-bold d-flex justify-content-end" style="color: #02843d; font-style: italic">
                                    ${ restaurant.currentCredits && restaurant.currentCredits >0 ? '$'+ parseFloat(restaurant.currentCredits).toFixed(2):0}
                                </div>
                            </div>
                            <div class="col-12 text-left mt-2">
                                ${ expires_credits.credits && restaurant.currentCredits ?
                                    `<div class="font-italic" style="color: #BF2519">
                                        $${parseFloat(restaurant.currentCredits).toFixed(2)} expires in ${expires_credits.days +1} days
                                    </div>`
                                    : ''
                                }
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-between align-items-end">
                            <a class="ff-details-for-restaurant" data-restaurant-id="${restaurant.id}" href="javascript:void(0)" style="color: #02843d; font-size: 0.75rem;">
                                <i class="fa fa-info-circle mr-1" aria-hidden="true" style="font-size: 18px; vertical-align: bottom;"></i><em>See Details</em>
                            </a>
                            ${ parseFloat(restaurant.currentCredits) > 0 ? `
                            <a class="btn btn-success" href="/restaurant/${restaurant.slug || restaurant.id}" style="padding: 4px 28px; font-size: 14px;">
                                Use
                            </a> `:``}
                        </div>
                    </div>
                </div>
            `;
        });
        let swiper_html = `
            <div class="swiper-container free-food-swiper p-3">
                <div class="swiper-wrapper">
                    ${sliders_html}
                </div>
                <div class="swiper-pagination"></div>
                ${
                    screen.width > 768 ? 
                        `<div class="swiper-button-prev">
                            <i class="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>    
                        </div>
                        <div class="swiper-button-next">
                            <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
                        </div>`
                    : ''
                }

            </div>
        `;

        $list_restaurants.html(swiper_html)
        $list_restaurants.find('.free-food-swiper').css({
            width: '100%',
            height: '100%'
        })
        const swiper = new Swiper('.free-food-swiper', {          
            // slidesPerView: 3,
            freeMode: true,
            spaceBetween: 10,         
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                576: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                }
              }
        });
       let venueId = $('#venueId').val()
        if(venueId) {
        if(section != "#modal-restaurant-specific-credits-details") {
            GetCurrentRestaurantIncentive(restaurantIncentives)
        }
        else {
            GetCurrentRestaurantIncentive(restaurantIncentives, section="#modal-restaurant-specific-credits-details")
        }
        }
        else {
        $('#restaurant-incentive-accordion').find(`.ff-details-for-restaurant`).on('click', function(){
            let res_id = $(this).data('restaurant-id')
            let restaurant = _.find(RESTAURANT_CREDITS, item => item.id == res_id)
            return list_free_food_details(restaurant || [])
        })
        }
    };

    const renderRestaurantSpecified = function(restaurantIncentives, elementToRender = '#current-restaurant-incentive', section = '#free-food-section-restaurant') {
        if(elementToRender.length <=0) return;
        let $restaurantSpecificIncentives = $('#restaurant-specific-incentives');
        if(!_.size(restaurantIncentives)) {
            return $restaurantSpecificIncentives.html(`<div class="text-center w-100 mt-3">No restaurant found for current filter options</div>`);
        }
        $restaurantSpecificIncentives.html('')
        let sliders_html = ''
        _.forEach(restaurantIncentives,(restaurant, index)=> {
        if(restaurant){
            restaurant.totalCredit = restaurant ? restaurant.totalCredit : 0;
            restaurant.currentCredits = restaurant ? restaurant.currentCredits : 0;
           let expires_credits = get_expired_credit(restaurant)
           sliders_html += `
                <div class="swiper-slide p-3" style="box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); transition: all 0.3s cubic-bezier(.25,.8,.25,1); border: none; border-radius: 10px;">
                   <div class="w-100 d-flex align-content-between flex-wrap" style="min-height: 150px">
                       <div class="row">
                           <div class="col-8 text-left">
                               <div class="font-weight-bold" style="font-size: 19px; color: #02843d;">
                                   <a href="/restaurant/${restaurant.slug || restaurant.id}">${restaurant.name}</a>
                                </div>
                               <div class="font-italic" style="font-size: 14px">${restaurant.address}</div>
                            </div>
                            <div class="col-4 text-right">
                               <div class="font-weight-bold d-flex justify-content-end" style="color: #02843d; font-style: italic">
                                   ${ restaurant.currentCredits && restaurant.currentCredits >0 ? '$'+ parseFloat(restaurant.currentCredits).toFixed(2) :0}
                               </div>
                            </div>
                            <div class="col-12 text-left mt-2">
                                ${ expires_credits.credits ?
                                   `<div class="font-italic" style="color: #BF2519">
                                       $${parseFloat(expires_credits.credits).toFixed(2)} expires in ${expires_credits.days +1} days
                                    </div>`
                                 : ''
                                }
                            </div>
                       </div>
                       <div class="w-100 d-flex justify-content-between align-items-end">
                            <a class="ff-details-for-specific-restaurant" data-restaurant-id="${restaurant.id}" href="javascript:void(0)" style="color: #02843d; font-size: 0.75rem;">
                            <i class="fa fa-info-circle mr-1" aria-hidden="true" style="font-size: 18px; vertical-align: bottom;"></i><em>See Details</em>
                            </a>
                            ${ parseFloat(restaurant.currentCredits) > 0 ? `
                            <a class="btn btn-success" href="/restaurant/${restaurant.slug || restaurant.id}" style="padding: 4px 28px; font-size: 14px;">
                               Use
                            </a> `:``}
                        </div>
                    </div>
                </div>
            `;
            }
        });
        let swiper_html = `
            <div class="swiper-container free-food-swiper p-3">
                <div class="swiper-wrapper">
                    ${sliders_html}
                </div>
            </div>
        `;
        $restaurantSpecificIncentives.html(swiper_html)
        $restaurantSpecificIncentives.find('.free-food-swiper').css({
            width: '100%',
            height: '100%'
        })
        const swiper = new Swiper('.free-food-swiper', {          
            // slidesPerView: 3,
            freeMode: true,
            spaceBetween: 10,         
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                576: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                }
              }
        });
        $('#current-restaurant-incentive').find(`.ff-details-for-specific-restaurant`).on('click', function(){
            let res_id = $(this).data('restaurant-id')
            let restaurant = _.find(RESTAURANT_CREDITS, item => item.id == res_id)
            return list_free_food_details(restaurant || [])
        })
    };
    function list_free_food_details(restaurant, open_modal = ''){
        let userId = $("#userId").val();
        _.forEach(restaurant.flow_in, item => {
            item.cash_flow = 'in'
            item.in_out_date = moment(item.creditDateTime).valueOf()
        })
        _.forEach(restaurant.flow_out, item => {
            item.cash_flow = 'out'
            item.in_out_date = moment(item.creditDateTime).valueOf()
        })
        //let cash_flow = _.orderBy(_.concat(restaurant.flow_in, restaurant.flow_out),['in_out_date'],['desc'])
        var cash_flow = _.chain(restaurant.flow_in)
        .concat(restaurant.flow_out)
        .orderBy(['in_out_date'], ['desc'])
        .filter(function (element) {
            return element.txnMadeBy === userId;
        })
        .uniqBy('creditId')
        .value();
        let credit_infor = ''
        _.forEach(cash_flow, (ele, index) => {
            let creditDateTime = ele.creditDateTime;
            creditDateTime ? creditDateTime = moment(creditDateTime).format('MM/DD/YY hh:mm a') : creditDateTime = '';
            let creditExpiryDate = ele.creditExpiryDate;
            if (ele.cash_flow == 'in') {
                creditExpiryDate ? creditExpiryDate = moment(creditExpiryDate).format('MM/DD/YY') : creditExpiryDate = '';
                credit_infor += `
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8 p-0">
                                <div class="w-100">
                                    ${ele && ele.orderId ? `<a style="color: darkslategrey" href="/orderhistory?showOrderHistory=true&historyOrderId=${ele.orderId}">#${ele.orderId}</a> -` : ''}
                                    ${!ele.orderId && ele.creditType ? 'Expired Credits - ' : ''}

                                    <span class="${!ele.orderId && ele.creditType || ele.creditAmount<0 || (ele.creditType!=='Free food' && ele.creditType!=='Restaurant Admin') ? 'red' : 'green'}">$${parseFloat(ele.creditAmount).toFixed(2)}<span>
                                    
                                </div>
                                <div class="w-100 font-italic font-13">Received credit ${creditDateTime}</div>
                                <div style="font-size: 13px">${ele && ele.reason_for_credit && ele.reason_for_credit != 'null'  ? ele.reason_for_credit : ''}</div>
                            </div>
                            <div class="col-4" style="color: #BF2519;">

                                ${creditExpiryDate && ele.usedAmount > 0 && ele.usedAmount < ele.creditAmount?
                                    `<div class="w-100" style="font-size: 15px">${(parseFloat(ele.creditAmount)-parseFloat(ele.usedAmount)).toFixed(2)} remaining expires ${creditExpiryDate || ''}</div>`
                                    : ''
                                }
                                ${creditExpiryDate && ele.usedAmount > ele.creditAmount?
                                    `<div class="w-100" style="font-size: 15px">Expired ${creditExpiryDate || ''}</div>`
                                    : ''
                                }
                                ${creditExpiryDate && ele && ele.usedAmount == 0?
                                    `<div class="w-100" style="font-size: 15px">Expires ${creditExpiryDate || ''}</div>`
                                    : ''
                                }

                            </div>
                        </div>
                    </li>
              `;
            } else {
                credit_infor += `
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8 p-0">
                                <div class="w-100">
                                    <a style="color: darkslategrey" href="/orderhistory?showOrderHistory=true&historyOrderId=${ele.orderId}">#${ele.orderId}</a> - <span style="color: #BF2519">$${parseFloat(ele.creditAmount).toFixed(2)}<span>
                                </div>
                                <div class="w-100" style="font-size: 13px">Paid ${creditDateTime}</div>
                                <div class="w-100" style="font-size: 13px">${ ele.creditReason!='Use Restaurant Credits' ? 'Credit removed due to canceled order' :ele.creditReason}</div>
                            </div>
                            <div class="col-4" style="color: darkslategrey;">
                                <div class="w-100" style="color: #BF2519; font-size: 15px">Used Credit</div>
                            </div>
                        </div>
                    </li>
                `;
            }
        })
        let html = `
            <div class="d-flex justify-content-between mb-3" style="align-items: center">
                <div class="text-center black" style="font-size: 20px; width: 85%;">${restaurant.name}</div>
                <i class="fa fa-times fa-2x" aria-hidden="true" data-izimodal-close="" style="color: darkgrey;"></i>
            </div>
            <ul class="list-group list-group-flush">
                ${credit_infor}
            </ul>
        `;
        if(window.location.href.includes('free-food-at-restaurant')) {
        $('#restaurant-free-food-details').find('.card-body').html(html)
        $('#restaurant-free-food-details').data('open-modal', open_modal)
        $('#restaurant-free-food-details').iziModal('open')
        }
        else {
            $('#restaurant-specific-free-food-details').find('.card-body').html(html)
            $('#restaurant-specific-free-food-details').data('open-modal', open_modal)
            $('#restaurant-specific-free-food-details').iziModal('open')
        }
    }
    function get_expired_credit(restaurant){
        _.forEach(restaurant.flow_in, item => {
            item.cash_flow = 'in'
            item.in_out_date = moment(item.creditDateTime).valueOf()
        })
       // let listCredit = _.filter(restaurant.flow_in, elem => elem.creditAmount > elem.usedAmount && moment(elem.creditExpiryDate).isAfter(moment()));
        let listCredit = _.filter(restaurant.flow_in, elem => (elem.creditAmount > elem.usedAmount || elem.creditAmount < elem.usedAmount) || (moment(elem.creditExpiryDate).isAfter(moment()) || !elem.creditExpiryDate));
        listCredit = _.orderBy(listCredit,['in_out_date'],['desc'])
        if (listCredit && listCredit.length > 0) {
            let min_expiry_date = moment(listCredit[0].creditExpiryDate).format('MM/DD/YYYY');
            let sum = 0
            _.forEach(listCredit, elem => {
                if(elem.creditExpiryDate && min_expiry_date && moment(elem.creditExpiryDate).format('MM/DD/YYYY') == min_expiry_date)  {
                    if( elem.creditAmount > elem.usedAmount){
                        sum += elem.creditAmount - elem.usedAmount
                    }
                }
                else {
                    return false;
                }
            })
            let expires_days = moment(min_expiry_date, 'MM/DD/YYYY hh:mm A').diff(moment(), 'days')
            return {
                credits: sum,
                days: expires_days
            }
        }
        return {
            credits: 0,
            days: 0
        }
    }
    function renderFreeFoodDetails(restaurant) {
        _.forEach(restaurant.flow_in, item => {
            item.cash_flow = 'in'
            item.in_out_date = moment(item.creditDateTime).valueOf()
        })
        _.forEach(restaurant.flow_out, item => {
            item.cash_flow = 'out'
            item.in_out_date = moment(item.creditDateTime).valueOf()
        })
        let cash_flow = _.orderBy(_.concat(restaurant.flow_in, restaurant.flow_out),['in_out_date'],['desc'])

        let table_body = '';
        let base_url = $('#baseUrlPlaterate').text();
        let clickHere = `<a href="${base_url}/restaurant/${ restaurant.slug || restaurant.id}">${restaurant.name || 'this restaurant'}</a>`;
        let usedCredits = 0;

        _.forEach(cash_flow, (ele, index) => {
            let order_name = ''
            switch(ele.order_type){
                case 'pickup':
                    order_name = 'Pick up'
                    break
                case 'delivery':
                    order_name = 'Delivery'
                    break
                case 'order-ahead':
                    order_name = 'Dine-in'
                    break
            }
            let creditDateTime = ele.creditDateTime;
            creditDateTime ? creditDateTime = moment(creditDateTime).format('MM/DD/YY h:mm a') : creditDateTime = '';
            let creditExpiryDate = ele.creditExpiryDate;
            if (ele.cash_flow == 'in') {
                creditExpiryDate ? creditExpiryDate = moment(creditExpiryDate).format('MM/DD/YY h:mm a') : creditExpiryDate = '';
                const creditAmtDisplay =
                table_body += `
                  <tr>
                    <th scope="row">
                        ${ele.orderId ? `<a style="color: #02843D" href="/orderhistory?showOrderHistory=true&historyOrderId=${ele.orderId}">#${ele.orderId}</a>` : ''}
                        ${!ele.orderId && ele.creditType != 'Expired Restaurant Credits' ? 'Gift - ' : ''}
                        ${!ele.orderId && ele.creditType == 'Expired Restaurant Credits' ? 'Expired - ' : ''}
                    </th>
                    <td>
                        ${ ele.creditAmount > 0 ?
                          `<i class="fa fa-plus" aria-hidden="true" style="color: #02843D"></i>$${parseFloat(ele.creditAmount).toFixed(2)}` :
                          `<i class="fa fa-minus" aria-hidden="true" style="color: #BF2519"></i>$${parseFloat(-ele.creditAmount).toFixed(2)}`
                        }
                    </td>
                    <td>${ele.reason_for_credit}</td>
                    <td>${creditDateTime}</td>
                    <td>${creditExpiryDate || ''}</td>
                  </tr>
              `;
            } else {
//this was leading to incorrect calculation       $${parseFloat(ele.restaurant_free_food) + parele.restaurant_ff).toFixed(2)}
                usedCredits += parseFloat(ele.creditAmount)

                table_body += `
                  <tr>
                    <th scope="row">
                        ${ele.orderId ? `<a style="color: #02843D" href="/orderhistory?showOrderHistory=true&historyOrderId=${ele.orderId}">#${ele.orderId}</a>` : 'Gift'}
                    </th>
                    <td>
                        <i class="fa fa-minus" aria-hidden="true" style="color: #BF2519"></i>
                        $${parseFloat(-ele.creditAmount).toFixed(2)}
                    </td>
                    <td>
                        Paid for <i>${order_name}</i> from <a href="/restaurant/${ele.venue_slug || ele.venue_id}" class="text-success">${ele.venue_name}</a>
                    </td>
                    <td>${moment(ele.creditDateTime).format('MM/DD/YYYY h:mm a')}</td>
                    <td></td>
                  </tr>
                `;
            }
        })


        let link_str = '';
        if (_.toNumber(restaurant.currentCredits) >= 0.005) {
            link_str = `
                <div class="alert alert-success" role="alert">
                    No credits will expire at this time. Go to ${clickHere} to use your free food.
                </div>
            `;
            let expiry_credits = _.filter(restaurant.flow_in, elem => elem.creditExpiryDate && moment(elem.creditExpiryDate).isAfter(moment()))
            if (_.size(expiry_credits)) {

              //changing logic to using the least date records adding up positive and then deducting usedCredits
                expiry_credits = _.orderBy(expiry_credits, ['creditExpiryDate'], ['asc'])
                let min_expiry_date = moment(expiry_credits[0].creditExpiryDate).format('MM/DD/YYYY hh:mm A');
                let sum = 0
                _.forEach(expiry_credits, elem => {
                    if(elem && elem.creditExpiryDate && min_expiry_date && moment(elem.creditExpiryDate).format('MM/DD/YYYY hh:mm A') == min_expiry_date)  {
                      if(elem.creditAmount > 0) sum += elem.creditAmount
                    }
                    else {
                      return false;
                    }
                })

                sum -= usedCredits;
                sum = Math.min(restaurant.currentCredits, sum);
                link_str = `
                    <div class="alert alert-warning" role="alert">
                        $${parseFloat(Math.abs(sum)).toFixed(2)} will expire at ${min_expiry_date}. Go to ${clickHere} to use it
                    </div>
                `;
            }
        } else {
            link_str = `
                <div class="alert alert-info" role="alert">
                    There is no available credit. Go to ${clickHere} and order to earn credits
                </div>
            `;
        }

        let detail_table = link_str + `
            <table class="table table-hover scroll-table-free-food">
            <thead>
                <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Received/Used Date</th>
                    <th scope="col">Expiration Date</th>
                </tr>
            </thead>
            <tbody>
                ${table_body}
            </tbody>
            </table>
        `;
        return detail_table;
    };

    $('#restaurant-incentive-accordion, #current-restaurant-incentive').on('click',function(){
        let element = event.target;
        if(!$(element).hasClass('use-free-food')) return
        useFreeFood(element);
    })
    function useFreeFood(element){
        let venue_id = $(element).data('venue-id') || $('#venueId').val();
        let current_url = window.location.href;
        if(_.includes(current_url, venue_id)){
            $("#collapseSix").collapse('hide');
            $("#collapseFive").collapse('hide');
            $("#collapseRestaurantCredit").collapse('hide');
            $('html, body').animate({ scrollTop: $("#headingFive").offset().top }, 1500, (finish)=>{
                let num = $('.highest-rated-menu-item').length;
                if(num < 10){
                $("#collapseSix").collapse('show')
                }else{
                    $("#collapseFive").collapse('show')
                }
            });
        }else{
            let redirect_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/restaurant/${venue_id}`;
            window.location.assign(redirect_url)
        }
    }

    function renderFriendReferralTableForEachMonth(referralsData) {
        // referralsData = JSON.parse(referralsData);
        referralsData.forEach(function(referral, index) {
            $(`#friendReferralTable-${index}`).DataTable({
                data: referral.friends,
                columns: [
                    {data: 'name', title: 'Name'},
                    {data: 'earned', title: 'Earned'}
                ],
                order: [[ 1, "ASC" ]],
                ordering: true,
                responsive: true,
                responsive: true,
                paging: false,
                searching: false,
                info: false,
                autoWidth: false
            });

            $(`#month-referral-${index}`).on('shown.bs.collapse', function () {
                $(`#heading-month-referral-${index}`).find(`.icon-collapse-${index}`).removeClass('fa-plus').addClass('fa-minus')
            })
            $(`#month-referral-${index}`).on('hidden.bs.collapse', function () {
               $(`#heading-month-referral-${index}`).find(`.icon-collapse-${index}`).removeClass('fa-minus').addClass('fa-plus')
            })
        });
    }
    // Gifts section
    var refresh_elem = '';
    var section_page = ''
    //
    function get_platerate_gift_history(usage_platerate_ff) {
        return $.promisedAjax({
            url: '/users/getListOfGiftsHistory',
            type: "POST",
            data: { },
        })
        .then(function(response) {
            renderListGifts(response)
            if(refresh_elem == 'gifts-detail')
                if(section_page == "#free-food-section-restaurant"){
                    $('#free-food-section-restaurant').find('#gifts-detail').collapse('show');
                }else{
                    $('#free-food-section-profile').find('#gifts-detail').collapse('show');
                }
        })
        .catch((error)=>{
            return false
        });
    }
    function renderListGifts(listGifts){
        let _tbody = '';
            let referalamount=Number(my_credits_infor.referral)+Number(my_credits_infor.totalPlaterateFreeFood)
           console.log("referalamount===",referalamount)
        
        _.forEach(listGifts,(ele,index)=>{
            let order_name = ''
            switch(ele.order_type){
                case 'pickup':
                    order_name = 'Pick up'
                    break
                case 'delivery':
                    order_name = 'Delivery'
                    break
                case 'order-ahead':
                    order_name = 'Dine-in'
                    break
            }
            let credit_date_time = moment(ele.creditDateTime || ele.order_placed_date).format('MM/DD/YY h:mm a')
            let credit_reason = ''
            if(ele.cash_flow == 'in'){
                credit_reason = ele.creditReason
            }else{
                credit_reason = `
                    Paid for <i>${order_name}</i> from <a href="/restaurant/${ele.venue_slug || ele.venue_id}" class="text-success">${ele.venue_name}</a>
                `;
            }
             _tbody += `
                <tr>
                    <th scope="row">
                        ${ele.orderId || ele.order_id ? `<a style="color: #02843D" href="/orderhistory?showOrderHistory=true&historyOrderId=${ele.orderId || ele.order_id}">#${ele.orderId || ele.order_id}</a>` : ''}
                        ${!(ele.orderId || ele.order_id) && ele.creditType != 'Expired PlateRate Credits' ? 'Gift' : ''}
                        ${!(ele.orderId || ele.order_id) && ele.creditType == 'Expired PlateRate Credits' ? 'Expired' : ''}

                    </th>
                    <td>
                        ${ ele.creditAmount > 0 ?
                          `<i class="fa fa-plus" aria-hidden="true" style="color: #02843D"></i>$${parseFloat(ele.creditAmount).toFixed(2)}` :
                          `<i class="fa fa-minus" aria-hidden="true" style="color: #BF2519"></i>$${parseFloat(-ele.creditAmount).toFixed(2)}`
                        }
                    </td>
                    <td>${credit_reason}</td>
                    <td>${credit_date_time}</td>
                </tr>
            `;
        })
       let detail_table = `
            <table class="table">
            <thead>
                <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Received/Used Date</th>
                </tr>
            </thead>
            <tbody>
                ${_tbody}
            </tbody>
            </table>
        `;
        let html = `
            <div class="container">
                <div class="alert alert-success mx-3 mx-md-0" role="alert">
                    <div class="h4">Free food available: <b class="platerate-credits-balance">$${parseFloat(referalamount ? referalamount : 0).toFixed(2)}</b></div>
                    <div>You can use credits at any PlateRate restaurant</div>
                </div>
                <div id="gifts-detail" class="" style="max-height: 80vh; overflow: auto">
                    ${detail_table}
                </div>
            </div>
        `;
        $('#gifts-history').html(html);
        $('#gifts-history-profile').html(html);
    }
    var freeFoodsGeoLocation = (function () {
        var latitudeInput = document.getElementById("freefoodlat");
        var longitudeInput = document.getElementById("freefoodlong");
        var locationinput = document.getElementById("freefoodlocationinput");
        var $locationContainer = $('.location-container');
        var watchID;
        var savedLat = Cookies.get('posLat');
        var savedLon = Cookies.get('posLon');

        var _geoFindMe = function() {
            if (!navigator.geolocation) {
                locationinput.placeholder = "Location detection is disabled in your device";
                return;
            }
            _updateLocationPlaceholder("loading");
            commonGetLocation(_updatePositions, _errorOnLocation);

        };

        var _updatePositions = function(position) {
            var newLat = position.coords.latitude;
            var newLon = position.coords.longitude;
            savedLat = Cookies.get('posLat');
            savedLon = Cookies.get('posLon');
            $('#freefoodlat').val(newLat);
            $('#freefoodlong').val(newLon);

            if (newLat !== savedLat || newLon !== savedLon) {
                //Update input fields values
                latitudeInput ? latitudeInput.value = newLat : '';
                longitudeInput ? longitudeInput.value = newLon : '';

                //Save new location to cookie
                Cookies.set('posLat', newLat, { path: '/'});
                Cookies.set('posLon', newLon, { path: '/'});
            }

            $('#freefoodlat').trigger('change');
            if(!position.fromIp){
                _updateLocationPlaceholder("detected");
                $(".location-error").remove();
            }
        };

        var _errorOnLocation = function(e) {
            if(!e || e.code == 1){
                ipLookUp("permissionDenied",".notification-border",_updateLocationPlaceholder,_updatePositions);
            } else {
                ipLookUp("locationNotAvailable",".notification-border",_updateLocationPlaceholder,_updatePositions);
            }
            _updateLocationPlaceholder("error");
        };

        var _updateLocationPlaceholder = function(locationStatus, newInput) {
            let inputId ="freefoodlocationinput" //holds current typed location in case location loads location after user begins to type
            _updateLocationCommonPlaceholder(locationStatus,$locationContainer,inputId);
        };

        function commonfoodlocation(){
            if (navigator.permissions && navigator.geolocation) {
                navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                    if(result.state === "prompt") {
                        _geoFindMe();
                        Cookies.set('locationPermission', 'prompt');
                    } else if (result.state === "granted") {
                        _geoFindMe();
                        Cookies.set('locationPermission', 'granted');
                    } else if (result.state === "denied") {
                        ipLookUp("permissionDenied",".notification-border",_updateLocationPlaceholder,_updatePositions);
                    }
                });
            } else {
               // console.log('navigator Geolocation or permission not found')
                //Certain browsers, like Safari, do not support navigator permission so let's
                //Just start geoLocation detection without showing alerts.
                _geoFindMe();
            }
        }
        var init = function() {
            _updateLocationPlaceholder('loading');
            if(!check_iOSapp() && !check_androidApp()){
                 _updateLocationPlaceholder('loading');
                let savedLat = Cookies.get('posLat');
                let savedLon = Cookies.get('posLon');
                if(savedLat==null){
                    if (navigator.permissions && navigator.geolocation) {
                        navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                                if(result.state === "prompt") {
                                    _geoFindMe();
                                    Cookies.set('locationPermission', 'prompt')
                                } else if (result.state === "granted") {
                                _geoFindMe();
                                    Cookies.set('locationPermission', 'granted');
                                } else if (result.state === "denied") {
                                    ipLookUp("permissionDenied","#showlocationmessage-container",_updateLocationPlaceholder,_updatePositions);
                                }
                            });
                        } else {
                           // console.log('navigator Geolocation or permission not found')
                            //Certain browsers, like Safari, do not support navigator permission so let's
                            //Just start geoLocation detection without showing alerts.
                            _geoFindMe();
                        }

                }
                else{
                    //_geoFindMe();
                    Cookies.set('locationPermission', 'granted');
                }
            }
        };

        return {
            init: init,
            updateLocationPlaceholder:_updateLocationPlaceholder,
            ipLookUp: ipLookUp,
            updatePositions: _updatePositions
        }
    })();
    freeFoodsGeoLocation.init();
window.freeFoodsGeoLocation = freeFoodsGeoLocation;
})
